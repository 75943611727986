.Games {
    padding-top: 1rem; 
    font-family: 'Montserrat', sans-serif;
    background-color: rgba(22,25,35,1);
    color: white;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");

.Games-tgapack {
    background-image: url('https://cdn.tga.fyi/tga/bg15.png'); 
    background-position: center; 
    background-position: 25%; 
    background-size: 112%;
    border-bottom: 1px solid rgb(226, 226, 226);
}
.Games-TLB {
    padding-top: 1rem; 
    border-bottom: 1px solid rgb(226, 226, 226);
    background-image: url('https://cdn.tga.fyi/tga/tlbbg4.png'); 
    background-position: center; 
    background-position: 0%; 
    background-size: 100%;
}
.Games-TGARP {
    padding-top: 1rem; 
    border-bottom: 1px solid rgb(226, 226, 226);
    background-image: url('https://cdn.tga.fyi/tga/tgarpbg1.png'); 
    background-position: center; 
    background-position: 0%; 
    background-size: 100%;
}

.Games-rust {
    padding-top: 1rem; 
    background-image: url('https://cdn.tga.fyi/tga/rustbg1.png'); 
    background-position: center; 
    background-position: 0%; 
    background-size: 100%;
}

@media only screen and (max-width: 1000px) {
    .Games {
        text-align: right;
    }
    .Games-tgapack {
        background-image: none;
    }
    .Games-TLB {
        background-image: none;
    }
    .Games-TGARP {
        background-image: none;
    }
    .Games-rust {
        background-image: none;
    }
    .Games-image img {
        height: 196px;
    }

    .Games-Order-First {
        order: 13;
    }
    .Games-Order-Last {
        order: -1;
    }
}

.Games-image {
    display: flex;
    justify-content: center;
}

.Games-row {
    padding-bottom: 1rem;
}
.Games-Align-Right {
    text-align: right;
}