.Header-logo {
    height: 30px;
}

header {
    background-image: url(https://cdn.tga.fyi/tga/topBack.png);
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100%;
}

.Header-row-img img {
    float: right !important;
}

.Header-btn-dl {
    padding: 1rem;
    background-color: #ec980656;
    color: white;
    width: 100%;
    font-size: larger;
    font-weight: bold;
}

.Header-btn-dl:hover {
    background-color: rgba(255, 255, 255, 0.25);
    color: #ec9806;
}

.Header-nav {
    padding-top: 3em;
    padding-bottom: 3em;
}

.Header-button {
    background-color: rgba(255, 255, 255, 0.25);
    margin-right: 2em;
    width: 80px;
    text-align: center;
    transition: 2s;
    border: solid 1px rgba(255, 255, 255, 0.1);
    color: white;
}
.Header-button:last-child {
    background-color: rgba(255, 255, 255, 0.25);
    width: 73px;
    margin-right: 0;
    text-align: center;
    transition: 2s;
    border: solid 1px rgba(255, 255, 255, 0.1);
    color: white;
}

.Header-button:hover {
    border: solid 1px rgba(255, 255, 255, 1);
    color: #ec9706;
}

.Header-toggler {
    border: var(--bs-border-width) solid white;
}

.Header-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");}

@media only screen and (max-width: 1000px) {
    .Header-button {
        margin-top: 1em;
        padding: 1em 1em;
        width: 146px;
    }    
    header {
        background-image: url(https://s3.tga.fyi/thegoldapple.com/images/topBack.png);
        background-position: top;
        background-repeat: no-repeat;
        background-size: auto;
        text-align: center;
    }
    .Header-join {
        text-align: center;
        padding-top: 2rem;
        padding-bottom: 1rem;
        padding-left: 2rem;
        padding-right: 2rem;
        border: dashed white 1px;
        font-size: 12pt !important;
        font-weight: bolder;
        background-color: rgba(236, 151, 6, 0.15);
        font-family: 'Montserrat';
    }
}

.Header-join {
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 1rem;
    border: dashed white 1px;
    font-size: 28pt;
    font-weight: bolder;
    background-color: rgba(236, 151, 6, 0.15);
    font-family: 'Montserrat';
}

@import url('https://fonts.googleapis.com/css2?family=Overpass:wght@200&display=swap');

.Header-mission-statement {
    color: white;
    font-size: xx-large;
    font-family: 'Overpass';
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

.Header-fade {
    height: 200px;
    background-image: linear-gradient(to bottom, rgba(255,0,0,0), rgba(22,25,35,1));
}